import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faYoutube, faFacebook} from '@fortawesome/free-brands-svg-icons';
import '../css/socialMediaIcons.css';
import ReactGA from 'react-ga';

const SocialMediaIcons = () => {
  const trackSocialMediaClick = (platform) => {
    ReactGA.event({
      category: 'Social Media',
      action: `Clicked ${platform}`,
      label: 'Social Media Icon Click'
    });
  };

  return (
    <div className='text-center'>
      <a href="https://www.instagram.com/techgeniedev/"
         target="_blank"
         rel="noopener noreferrer"
         className="social-icon text-white me-3"
         onClick={() => trackSocialMediaClick('Instagram')}>
        <FontAwesomeIcon icon={faInstagram} size="lg" />
      </a>
      <a href="https://www.youtube.com/@TechGenieDev"
         target="_blank"
         rel="noopener noreferrer"
         className="social-icon text-white me-3"
         onClick={() => trackSocialMediaClick('YouTube')}>
        <FontAwesomeIcon icon={faYoutube} size="lg" />
      </a>
      <a href="https://www.facebook.com/profile.php?id=100088374507339&mibextid=ZbWKwL"
         target="_blank"
         rel="noopener noreferrer"
         className="social-icon text-white me-3"
         onClick={() => trackSocialMediaClick('Facebook')}>
        <FontAwesomeIcon icon={faFacebook} size="lg" />
      </a>
    </div>
  );
}

export default SocialMediaIcons;
