import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import "../css/HeaderWithSidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBook,
  faBookOpen,
  faTools,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga";

// Initialize ReactGA only once
ReactGA.initialize("G-Y7CVQ60D2H");

const HeaderWithSidebar = ({ logo, sidebarItems }) => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState("");
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  useEffect(() => {
    // Track page view on location change
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  useEffect(() => {
    const path = location.pathname;
    let item;

    if (path === "/") {
      item = "Home";
    } else {
      item = path
        .replace("/", "")
        .split("/")
        .map(
          (part) =>
            part.charAt(0).toUpperCase() + part.slice(1).replace("-", " ")
        )
        .join(" ");
    }

    setActiveItem(item || "Home");
  }, [location]);

  const handleSidebarItemClick = (item) => {
    setActiveItem(item);
    setIsNavCollapsed(true); // Collapse the navbar after clicking on a link
    ReactGA.event({
      category: "Sidebar",
      action: `${item} Clicked`,
      label: "HeaderWithSidebar Navigation",
    });
  };

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  return (
    <div className="header text-light py-2">
      <div className="container-fluid d-flex justify-content-between align-items-center">
        <div className="logo">
          <NavLink to="/">{logo}</NavLink>
        </div>
        <nav className="navbar navbar-expand-md navbar-light">
          <button
            className="navbar-toggler"
            type="button"
            onClick={handleNavCollapse}
            aria-controls="navbarNav"
            aria-expanded={!isNavCollapsed}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${
              !isNavCollapsed ? "show" : ""
            }`}
            id="navbarNav"
          >
            <ul className="navbar-nav ms-auto">
              {sidebarItems &&
                sidebarItems.map((item, index) => (
                  <li
                    key={index}
                    className={`nav-item ${
                      activeItem === item ||
                      (item === "Tools" && location.pathname.startsWith("/tools")) ||
                      (item === "Websites" && location.pathname.startsWith("/websites"))
                        ? "active active-bg"
                        : ""
                    }`}
                  >
                    <Link
                      to={
                        item === "Home"
                          ? "/"
                          : item === "Tools"
                          ? "/tools"
                          : item === "Websites"
                          ? "/websites"
                          : `/${item.toLowerCase().replace(/ /g, "-")}`
                      }
                      className={`nav-link ${
                        activeItem === item ? "active" : ""
                      }`}
                      aria-current={activeItem === item ? "page" : undefined}
                      onClick={() => handleSidebarItemClick(item)}
                    >
                      {item === "Home" && (
                        <FontAwesomeIcon icon={faHome} className="me-2" />
                      )}
                      {item === "Courses" && (
                        <FontAwesomeIcon icon={faBook} className="me-2" />
                      )}
                      {item === "Books" && (
                        <FontAwesomeIcon icon={faBookOpen} className="me-2" />
                      )}
                      {item === "Tools" && (
                        <FontAwesomeIcon icon={faTools} className="me-2" />
                      )}
                      {item === "Websites" && (
                        <FontAwesomeIcon icon={faGlobe} className="me-2" />
                      )}
                      {item}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default HeaderWithSidebar;
