import React, { useState, useEffect, useMemo } from "react";
import "../css/Courses.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  FaSearch,
  FaTimes,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import { Input, InputGroup, InputGroupText, Button } from "reactstrap";
import ReactPaginate from "react-paginate";
import ReactGA from "react-ga";
import { useLocation, useNavigate } from "react-router-dom";

import JavaCourses from "../courses/java.json";
import PythonCourses from "../courses/python.json";
import WebDevelopmentCourses from "../courses/web_development.json";
import DigitalMarketingCourses from "../courses/digital_marketing.json";
import DatabasesCourses from "../courses/databases.json";
import SoftwareTestingCourses from "../courses/software_testing.json";
import CanonicalTag from "./CanonicalTag";

const categoryMap = {
  "Java & Frameworks": JavaCourses,
  "Python & Frameworks": PythonCourses,
  "Web Development": WebDevelopmentCourses,
  "Digital Marketing": DigitalMarketingCourses,
  Databases: DatabasesCourses,
  "Software Testing": SoftwareTestingCourses,
};

const categories = [
  "Java & Frameworks",
  "Python & Frameworks",
  "Web Development",
  "Digital Marketing",
  "Databases",
  "Software Testing",
];

const CoursesPage = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [courses, setCourses] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("Web Development");
  const [currentPage, setCurrentPage] = useState(0);
  const coursesPerPage = 8;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.initialize("G-Y7CVQ60D2H");
    ReactGA.pageview("/courses");

    const params = new URLSearchParams(location.search);
    const categoryFromUrl = params.get("category");
    if (categoryFromUrl && categories.includes(categoryFromUrl)) {
      setSelectedCategory(categoryFromUrl);
    }
  }, [location.search]);

  useEffect(() => {
    const fetchCourses = () => {
      const selectedCourses =
        selectedCategory === "All"
          ? Object.values(categoryMap).flat()
          : categoryMap[selectedCategory] || [];
      setCourses(selectedCourses);
    };
    setCurrentPage(0); // Reset to the first page
    fetchCourses();
  }, [selectedCategory]);

  const filteredCourses = useMemo(() => {
    const lowercasedQuery = searchQuery.toLowerCase().trim();
    return courses.filter(
      (course) =>
        course.title.toLowerCase().includes(lowercasedQuery) ||
        course.description.toLowerCase().includes(lowercasedQuery)
    );
  }, [searchQuery, courses]);

  const indexOfLastCourse = (currentPage + 1) * coursesPerPage;
  const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
  const currentCourses = filteredCourses.slice(
    indexOfFirstCourse,
    indexOfLastCourse
  );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(0); // Reset to the first page when searching
    ReactGA.event({
      category: "Search",
      action: "Searched for courses",
      label: e.target.value,
    });
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setCurrentPage(0); // Reset to the first page when clearing
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setCurrentPage(0); // Reset to the first page
    setSearchQuery("");
    const params = new URLSearchParams(location.search);
    params.set("category", category);
    navigate(`?${params.toString()}`);
    ReactGA.event({
      category: "Category",
      action: "Changed category",
      label: category,
    });
  };

  const handlePageChange = (data) => {
    setCurrentPage(data.selected);
    ReactGA.event({
      category: "Pagination",
      action: "Changed page",
      label: `Page ${data.selected + 1}`,
    });
  };

  return (
    <>
      <CanonicalTag url="/courses" />
      <div className="course-page">
        <div className="course-hero-section container-fluid">
          <div className="container py-4">
            <div className="row mb-2">
              <div className="col">
                <h1 className="title">
                  Unlock Your Potential with Our Comprehensive Courses
                </h1>
                <p className="mt-4 course-description">
                  Discover a wide range of courses tailored to meet your
                  learning needs. Whether you're interested in programming, web
                  development, or digital marketing, we have something for
                  everyone. Start your journey towards mastery and unlock new
                  career opportunities with our expertly curated content.
                </p>
                <div className="search-container">
                  <InputGroup className="my-4" style={{ maxWidth: "650px" }}>
                    <InputGroupText>
                      <FaSearch />
                    </InputGroupText>
                    <Input
                      type="text"
                      placeholder="Search for courses..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                    {searchQuery && (
                      <Button
                        color="light"
                        className="clear-button"
                        onClick={handleClearSearch}
                        style={{
                          border: "1px solid #ced4da",
                          borderRadius: "0 4px 4px 0",
                          boxShadow: "none",
                        }}
                      >
                        <FaTimes />
                      </Button>
                    )}
                  </InputGroup>
                </div>
                <div className="category-filter d-flex flex-wrap justify-content-center">
                  {categories.map((category) => (
                    <button
                      key={category}
                      type="button"
                      className={`btn btn-primary mx-2 my-2 ${
                        selectedCategory === category ? "active" : ""
                      }`}
                      onClick={() => handleCategoryChange(category)}
                      aria-label={`Filter by ${category} category`}
                    >
                      {category}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            {currentCourses.length > 0 ? (
              currentCourses.map((course) => (
                <div key={course.id} className="col-md-6 col-lg-3 mb-4">
                  <div className="card h-100">
                    <picture>
                      <source
                        srcSet={`${course.imagePath}.webp`}
                        type="image/webp"
                      />
                      <img
                        src={`${course.imagePath}.jpg`}
                        className="card-img-top"
                        alt={course.title}
                        loading="lazy" // Add this attribute to enable lazy loading
                      />
                    </picture>
                    <div className="card-body">
                      <h5 className="card-title">{course.title}</h5>
                      <p className="card-text">{course.description}</p>
                    </div>
                    <div className="card-footer">
                      <a
                        href={course.url}
                        className="btn btn-primary"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Course
                      </a>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center">No courses found.</p>
            )}
          </div>
        </div>

        <div className="d-flex justify-content-center mt-4">
          {filteredCourses.length > coursesPerPage && (
            <ReactPaginate
              previousLabel={
                window.innerWidth <= 768 ? <FaChevronLeft /> : "Previous"
              }
              nextLabel={window.innerWidth <= 768 ? <FaChevronRight /> : "Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={Math.ceil(filteredCourses.length / coursesPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              previousClassName={"page-item"}
              nextClassName={"page-item"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousLinkClassName={"page-link"}
              nextLinkClassName={"page-link"}
              forcePage={currentPage}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CoursesPage;
