import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga";
import "../css/FeaturedCourses.css";
import courseData from '../courses/courses_home.json';

const FeaturedCourses = () => {
  const imagePath = process.env.PUBLIC_URL + "/images/";
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize("G-Y7CVQ60D2H");
    ReactGA.pageview(location.pathname); // Track page view
  }, [location.pathname]);

  const [courses] = useState(courseData);

  const handleMoreCoursesClick = () => {
    ReactGA.event({
      category: "Navigation",
      action: "Click More Courses",
      label: "More Courses Button",
    });
  };

  const handleCourseClick = (courseTitle) => {
    ReactGA.event({
      category: "Navigation",
      action: `Click Visit Udemy`,
      label: `Visit Udemy - ${courseTitle}`,
    });
  };

  const handleMoreCoursesButtonClick = () => {
    handleMoreCoursesClick();
  };

  return (
    <div className="featured-courses">
      <div className="container">
        <div className="row align-items-center mb-4">
          <div className="col-md-10">
            <h2 className="featured-courses-title">Featured Courses</h2>
            <p className="featured-courses-description">
              Explore a diverse selection of featured courses tailored to help you enhance your skills and knowledge. 
              Whether you're looking to advance your career, learn a new skill, or explore a new hobby, our curated courses offer valuable insights and practical applications. 
              Join us on this journey of learning and personal growth!
            </p>
          </div>
          <div className="col-md-2 text-md-end">
            <a
              href="/courses"
              className={`btn btn-primary ${location.pathname === "/courses" ? "active" : ""}`}
              onClick={handleMoreCoursesButtonClick}
            >
              More Courses
            </a>
          </div>
        </div>
        <div className="row">
          {courses.map((course) => (
            <div className="col-md-6 col-lg-3 mb-4" key={course.id}>
              <div className="card h-100">
                <picture>
                  <source srcSet={`${imagePath}${course.image}.webp`} type="image/webp" />
                  <img
                    src={`${imagePath}${course.image}.jpg`}
                    className="card-img-top"
                    alt={course.title}
                    loading = "lazy"
                  />
                </picture>
                <div className="card-body">
                  <h5 className="card-title">{course.title}</h5>
                  <p className="card-description">{course.description}</p>
                </div>
                <div className="card-footer">
                  <a
                    href={course.url}
                    className="btn btn-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => handleCourseClick(course.title)}
                  >
                    Visit Udemy
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturedCourses;