import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../css/footer.css";
import SocialMediaIcons from "./SocialMediaIcons";
import ReactGA from "react-ga";

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  const handleLinkClick = (category, action, label, path) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
    navigate(path); // Use navigate for routing
  };

  const getLinkClassName = (path) => {
    return location.pathname === path ? "active" : "";
  };

  return (
    <footer className="footer mt-auto text-light py-4">
      <div className="container">
        <div className="row text-center text-md-start">
          {/* About Section */}
          <div className="col-md-4 mb-4">
            <h5 className="footer-heading">About Tech Genie Dev</h5>
            <p>
              Tech Genie Dev is your go-to platform for learning resources,
              courses, ebooks, and essential tools. Discover curated content
              tailored to boost your skills, whether you're starting out or
              looking to advance. Stay updated with the latest trends in
              technology and education.
            </p>
          </div>

          {/* Quick Links Section */}
          <div className="col-md-4 mb-4">
            <h5 className="footer-heading">Quick Links</h5>
            <ul className="list-unstyled d-flex flex-column align-items-center">
              <li>
                <a
                  href="/"
                  className={getLinkClassName("/")}
                  onClick={() =>
                    handleLinkClick("Footer", "Click Home", "Home Link", "/")
                  }
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/courses"
                  className={getLinkClassName("/courses")}
                  onClick={() =>
                    handleLinkClick(
                      "Footer",
                      "Click Courses",
                      "Courses Link",
                      "/courses"
                    )
                  }
                >
                  Courses
                </a>
              </li>
              <li>
                <a
                  href="/books"
                  className={getLinkClassName("/books")}
                  onClick={() =>
                    handleLinkClick(
                      "Footer",
                      "Click Books",
                      "Books Link",
                      "/books"
                    )
                  }
                >
                  Books
                </a>
              </li>
              <li>
                <a
                  href="/tools"
                  className={getLinkClassName("/tools")}
                  onClick={() =>
                    handleLinkClick(
                      "Footer",
                      "Click Tools",
                      "Tools Link",
                      "/tools"
                    )
                  }
                >
                  Tools
                </a>
              </li>
              <li>
                <a
                  href="/websites"
                  className={getLinkClassName("/websites")}
                  onClick={() =>
                    handleLinkClick(
                      "Footer",
                      "Click Websites",
                      "Websites Link",
                      "/websites"
                    )
                  }
                >
                  Websites
                </a>
              </li>
            </ul>
          </div>

          {/* Follow Us Section */}
          <div className="col-md-4 mb-4">
            <h5 className="footer-heading">Follow Us</h5>
            <p>
              Stay connected with us on social media to get the latest updates,
              tips, and news. Follow us for regular content and updates that
              will help you stay ahead in your learning journey.
            </p>
            <SocialMediaIcons />
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className="row mt-4">
          <div className="col text-center">
            <p className="mb-0">
              &copy; {new Date().getFullYear()} Tech Genie Dev. All rights
              reserved!
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
