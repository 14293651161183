import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import ReactPaginate from "react-paginate";
import {
  FaSearch,
  FaTimes,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import ReactGA from "react-ga";
import websites from "../learning resources/websites.json";
import "../css/LearningResourcesPage.css";
import CanonicalTag from "./CanonicalTag";

const WebsitesPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 12;

  useEffect(() => {
    ReactGA.pageview("/websites");
  }, []);

  useEffect(() => {
    // Reset page number to 0 when search term changes
    setCurrentPage(0);
  }, [searchTerm]);

  const trackEvent = (category, action, label) => {
    ReactGA.event({ category, action, label });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    trackEvent("Search", "User searched for a website", e.target.value);
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
    trackEvent("Pagination", "User changed page", `Page ${selected + 1}`);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setCurrentPage(0);
  };

  const filterResources = (resources) =>
    resources.filter((resource) =>
      resource.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const activeResources = filterResources(websites);
  const pageCount = Math.ceil(activeResources.length / itemsPerPage);
  const displayedResources = activeResources.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <>
      <CanonicalTag url="/websites" />
      <Container className="py-4 description">
        <Row>
          <Col>
            <h1 className="title">Useful Websites</h1>
            <p className="mt-4 copy-text">
              Discover a curated selection of informative websites designed to
              elevate your learning journey. Whether you're seeking educational
              content, research tools, or interactive platforms, our collection
              has you covered. Utilize the search functionality to easily find
              and explore sites that match your interests. Unlock new knowledge
              and resources to support your educational goals.
            </p>
            <div className="search-container">
              <InputGroup style={{ maxWidth: "650px" }}>
                <InputGroupText>
                  <FaSearch aria-label="Search icon" />
                </InputGroupText>
                <Input
                  type="text"
                  placeholder="Search for websites..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  aria-label="Search for websites"
                />
                {searchTerm && (
                  <Button
                    color="light"
                    className="clear-button"
                    onClick={handleClearSearch}
                    aria-label="Clear search"
                    style={{
                      border: "1px solid #ced4da",
                      borderRadius: "0 4px 4px 0",
                      boxShadow: "none",
                    }}
                  >
                    <FaTimes />
                  </Button>
                )}
              </InputGroup>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          {displayedResources.length > 0 ? (
            displayedResources.map((resource) => (
              <Col
                lg="3"
                md="4"
                sm="6"
                xs="12"
                key={resource.id}
                className="mb-4"
              >
                <a
                  href={resource.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="resource-card-link"
                >
                  <Card className="resource-card">
                    <CardBody>
                      <img
                        src={resource.image}
                        alt={resource.name}
                        className="resource-image"
                      />
                      <CardTitle tag="h5">{resource.name}</CardTitle>
                      <CardText>{resource.description}</CardText>
                    </CardBody>
                  </Card>
                </a>
              </Col>
            ))
          ) : (
            <Col className="text-center no-results">
              <h5>No websites found.</h5>
              <p>Try adjusting your search criteria.</p>
              <img
                src={`${process.env.PUBLIC_URL}/images/404-error.png`}
                alt="No results"
                className="img-fluid"
              />
            </Col>
          )}
        </Row>
        {activeResources.length > itemsPerPage && (
          <Row>
            <Col className="d-flex justify-content-center mt-4">
              <ReactPaginate
                previousLabel={
                  window.innerWidth <= 768 ? <FaChevronLeft /> : "Previous"
                }
                nextLabel={
                  window.innerWidth <= 768 ? <FaChevronRight /> : "Next"
                }
                breakLabel={"..."}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={currentPage}
              />
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default WebsitesPage;
