import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import '../css/books.css';
import ReactGA from 'react-ga';
import bookData from '../books/books_home.json'; // Import the JSON data

// Mapping of icon names to FontAwesome icons
const iconMap = {
  faBook: faBook
};

const Books = () => {
  const [books, setBooks] = React.useState([]);

  useEffect(() => {
    // Initialize Google Analytics if not already initialized
    if (!window.gaInitialized) {
      ReactGA.initialize('G-Y7CVQ60D2H');
      window.gaInitialized = true;
    }
    // Track page view
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Map icon names to FontAwesome icons and set books data
    const updatedData = bookData.map(book => ({
      ...book,
      icon: iconMap[book.icon]
    }));
    setBooks(updatedData);
  }, []);

  const handleBookLinkClick = (book) => {
    // Track event
    ReactGA.event({
      category: 'Books',
      action: 'Buy Now clicked',
      label: book.title,
    });
    // Open link in a new tab
    window.open(book.link, '_blank');
  };

  const handleMoreBooksClick = () => {
    // Track event
    ReactGA.event({
      category: 'Books',
      action: 'More Books clicked',
    });
  };

  return (
    <div className="books container-fluid">
      <div className="container">
        <div className="row align-items-center mb-4">
          <div className="col-md-10">
            <h2 className="books-title">Books</h2>
            <p className="books-description">
              Explore our diverse collection of books that cater to various interests and fields. 
              Whether you're looking to expand your knowledge, enhance your skills, or simply enjoy a good read, 
              our selection has something for everyone. Discover insightful content that inspires learning and growth, 
              and embark on your journey of knowledge today!
            </p>
          </div>
          <div className="col-md-2 text-md-end">
            <a href="/books" className="btn btn-primary" onClick={handleMoreBooksClick}>
              More Books
            </a>
          </div>
        </div>
        <div className="row">
          {books.map((book, index) => (
            <div className="col-md-6 col-lg-3 mb-4" key={index}>
              <div className="card h-100">
                <div className="card-body text-center">
                  <FontAwesomeIcon icon={book.icon} size="3x" className="mb-3" />
                  <h5 className="card-title">{book.name}</h5>
                  <p className="card-text">{book.description}</p>
                </div>
                <div className="card-footer">
                  <button onClick={() => handleBookLinkClick(book)} className="btn btn-primary">
                    Buy Now
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Books;