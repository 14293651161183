import React from 'react';
import '../css/whyChooseUsSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faCertificate, faUsers } from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga'; // Import react-ga

const WhyChooseUs = () => {
  const trackCardClick = (cardTitle) => {
    ReactGA.event({
      category: 'Why Choose Us',
      action: 'Clicked Feature Card',
      label: cardTitle
    });
  };

  return (
    <div className="why-choose-us">
      <div className="container">
        <h2 className="section-title text-center">Why Choose Us?</h2>
        <p className="section-subtitle text-center mb-5">Discover why our courses are the best choice for your learning journey.</p>
        <div className="row">
          <div className="col-md-4 mb-3">
            <div className="card text-center" onClick={() => trackCardClick('Expert Instructors')}>
              <div className="card-icon">
                <FontAwesomeIcon icon={faRocket} />
              </div>
              <div className="card-content">
                <h3 className="card-title">Expert Instructors</h3>
                <p className="card-text">Learn from industry experts dedicated to your success.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="card text-center" onClick={() => trackCardClick('Certification')}>
              <div className="card-icon">
                <FontAwesomeIcon icon={faCertificate} />
              </div>
              <div className="card-content">
                <h3 className="card-title">Certification</h3>
                <p className="card-text">Earn certificates to validate your skills and enhance your career prospects.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="card text-center" onClick={() => trackCardClick('Community Support')}>
              <div className="card-icon">
                <FontAwesomeIcon icon={faUsers} />
              </div>
              <div className="card-content">
                <h3 className="card-title">Community Support</h3>
                <p className="card-text">Join a supportive community of learners and mentors.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
