import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/heroSection.css';
import ReactGA from 'react-ga';
import { Container, Row, Col, Button } from 'react-bootstrap';

const imagePath = process.env.PUBLIC_URL + "/images/";

const HeroSection = () => {
  useEffect(() => {
    ReactGA.initialize('G-Y7CVQ60D2H');
    ReactGA.pageview('/'); // Track page view for the hero section
  }, []);

  const handleCoursesClick = () => {
    ReactGA.event({
      category: 'Engagement',
      action: 'Clicked "Start Learning Now"',
    });
  };

  return (
    <div className="hero-section">
      <Container>
        <Row className="align-items-center text-center text-lg-left">
          <Col md={12} lg={6} className="hero-text mb-4 mb-lg-0">
            <h1 className="hero-title">
              Empower Your Future with Tech Genie Dev
            </h1>
            <p className="hero-description">
              Dive into a curated selection of courses designed to propel your career forward. 
              Gain new skills and knowledge with our affordable and comprehensive content.
              Join us now and take the first step towards achieving your goals.
            </p>
            <Button variant="primary" as={Link} to="/courses" onClick={handleCoursesClick}>
              Start Learning Now
            </Button>
          </Col>
          <Col md={12} lg={6} className="hero-image">
            <picture>
              <source 
                srcSet={`${imagePath}hero_image.webp`} 
                type="image/webp" 
                sizes="(max-width: 600px) 100vw, (min-width: 601px) 50vw" 
              />
              <source 
                srcSet={`${imagePath}hero_image.jpg`} 
                type="image/jpeg" 
                sizes="(max-width: 600px) 100vw, (min-width: 601px) 50vw" 
              />
              <img 
                src={`${imagePath}hero_image.jpg`} 
                alt="Learning Journey" 
                className="img-fluid hero-img" 
              />
            </picture>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeroSection;
