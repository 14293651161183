import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";
import CoursesPage from "./components/CoursesPage";
import BooksPage from "./components/BooksPage";
import HomePage from "./components/HomePage";
import ToolsPage from "./components/ToolsPage";
import WebsitesPage from "./components/WebsitesPage";
import ReactGA from "react-ga";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HeaderWithSidebar from "./components/HeaderWithSidebar";
import Footer from "./components/Footer";

const TRACKING_ID = "G-Y7CVQ60D2H";
ReactGA.initialize(TRACKING_ID);

const Logo = () => {
  const logo = process.env.PUBLIC_URL + '/images/logo.png';
  return <img src={logo} alt="Tech Genie Dev Company Logo" />;
};

const App = () => {
  return (
    <div className="app">
      <BrowserRouter>
        <HeaderWithSidebar
          logo={<Logo />}
          sidebarItems={["Home", "Courses", "Books", "Tools", "Websites"]}
        />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/courses" element={<CoursesPage />} />
          <Route path="/books" element={<BooksPage />} />
          <Route path="/tools" element={<ToolsPage />} />
          <Route path="/websites" element={ <WebsitesPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
