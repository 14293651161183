import React from 'react';
import { Helmet } from 'react-helmet';

const CanonicalTag = ({ url }) => {
  const baseUrl = 'https://techgeniedev.in';
  const canonicalUrl = `${baseUrl}${url}`;

  return (
    <Helmet>
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
};

export default CanonicalTag;
