import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faGraduationCap, faChalkboardTeacher, faLaptopCode } from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga'; // Import react-ga

import '../css/UsefulWebsites.css';

const UsefulWebsites = ({ setActiveTab }) => {
  const websites = [
    { name: "FreeCodeCamp", url: "https://www.freecodecamp.org/", icon: faCode },
    { name: "Coursera", url: "https://www.coursera.org/", icon: faGraduationCap },
    { name: "Udemy", url: "https://www.udemy.com/", icon: faChalkboardTeacher },
    { name: "Codecademy", url: "https://www.codecademy.com/", icon: faLaptopCode },
  ];

  const handleWebsiteClick = (url, websiteName) => {
    window.open(url, '_blank'); // Open link in a new tab

    // Track the click event in Google Analytics
    ReactGA.event({
      category: 'Website',
      action: 'Clicked Visit Website',
      label: websiteName
    });
  };

  const handleMoreWebsitesClick = () => {
    // Track event
    ReactGA.event({
      category: 'Websites',
      action: 'More Websites clicked',
    });
  };

  return (
    <div className="useful-websites container-fluid">
      <div className="container">
        <div className="row align-items-center mb-4">
          <div className="col-md-10">
            <h2 className="useful-websites-title">Useful Websites</h2>
            <p className="useful-websites-description">
              Discover a curated list of websites that provide valuable resources for learning and enhancing your skills. 
              Whether you are looking to learn programming, improve your coding skills, or explore new subjects, these platforms offer a wealth of knowledge and courses to help you succeed.
              Dive into these resources and take your learning journey to the next level!
            </p>
          </div>
          <div className="col-md-2 text-md-end">
            <a href="/websites" className="btn btn-primary" onClick={handleMoreWebsitesClick}>
              More Websites
            </a>
          </div>
        </div>
        <div className="row">
          {websites.map((website, index) => (
            <div className="col-md-6 col-lg-3 mb-4" key={index}>
              <div className="card h-80">
                <div className="card-body text-center">
                  <FontAwesomeIcon icon={website.icon} size="3x" className="mb-3" />
                  <h5 className="card-title">{website.name}</h5>
                </div>
                <div className="card-footer">
                  <button onClick={() => handleWebsiteClick(website.url, website.name)} className="btn btn-primary">
                    Visit {website.name}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UsefulWebsites;