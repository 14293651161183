import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaintBrush, faTasks, faSpellCheck, faComments } from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga'; // Import react-ga

import '../css/UsefulTools.css';

const UsefulTools = () => {
  const tools = [
    { name: "Canva", url: "https://www.canva.com/", icon: faPaintBrush },
    { name: "Trello", url: "https://trello.com/", icon: faTasks },
    { name: "Grammarly", url: "https://www.grammarly.com/", icon: faSpellCheck },
    { name: "Slack", url: "https://slack.com/", icon: faComments },
  ];

  const handleToolClick = (url, toolName) => {
    // Open link in a new tab
    window.open(url, '_blank');
    
    // Track the click event in Google Analytics
    ReactGA.event({
      category: 'Tool',
      action: 'Clicked Visit Tool',
      label: toolName
    });
  };

  const handleMoreToolsClick = () => {
    // Track event
    ReactGA.event({
      category: 'Tools',
      action: 'More Tools clicked',
    });
  };

  return (
    <div className="useful-tools">
      <div className="container">
        <div className="row align-items-center mb-4">
          <div className="col-md-10">
            <h2 className="useful-tools-title">Useful Tools</h2>
            <p className="useful-tools-description">
              Discover a selection of essential tools that can enhance your productivity and streamline your workflow. 
              From design and project management to writing assistance and team communication, these resources are designed to help you work smarter and more efficiently. 
              Explore each tool to find the right fit for your needs and elevate your projects to the next level!
            </p>
          </div>
          <div className="col-md-2 text-md-end">
            <a 
              href="/tools" 
              className="btn btn-primary" 
              onClick={handleMoreToolsClick}
            >
              More Tools
            </a>
          </div>
        </div>
        <div className="row">
          {tools.map((tool, index) => (
            <div className="col-md-6 col-lg-3 mb-4" key={index}>
              <div className="card h-80">
                <div className="card-body text-center">
                  <FontAwesomeIcon icon={tool.icon} size="3x" className="mb-3" />
                  <h5 className="card-title">{tool.name}</h5>
                </div>
                <div className="card-footer">
                  <button 
                    onClick={() => handleToolClick(tool.url, tool.name)} 
                    className="btn btn-primary"
                  >
                    Visit {tool.name}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UsefulTools;