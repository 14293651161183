import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import ReactGA from "react-ga"; // Import ReactGA for Google Analytics
import HeroSection from "./HeroSection";
import FeaturedCourses from "./FeaturedCourses";
import UsefulTools from "./UsefulTools";
import UsefulWebsites from "./UsefulWebsites";
import Books from "./Books";
import WhyChooseUsSection from "./WhyChooseUsSection";
import CanonicalTag from "./CanonicalTag";

const HomePage = () => {
  // Track pageview when HomePage mounts
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div>
      <CanonicalTag url="/" />
      <HeroSection />
      <WhyChooseUsSection />
      <FeaturedCourses />
      <UsefulTools />
      <UsefulWebsites />
      <Books />
    </div>
  );
};

export default HomePage;
