import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import ReactPaginate from "react-paginate";
import { FaSearch, FaTimes, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import booksData from "../books/books.json";
import "../css/BooksPage.css";
import ReactGA from "react-ga";
import CanonicalTag from "./CanonicalTag";

const BooksPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const itemsPerPage = 8;

  useEffect(() => {
    ReactGA.initialize("G-Y7CVQ60D2H");

    // Handle window resize event to update isMobile state
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(0); // Reset to first page on search
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setCurrentPage(0); // Reset to first page when clearing
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const filterBooks = (books) =>
    books.filter((book) =>
      book.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const activeBooks = filterBooks(booksData);
  const pageCount = Math.ceil(activeBooks.length / itemsPerPage);
  const displayedBooks = activeBooks.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const renderBookCard = (book) => (
    <Col lg="3" md="4" sm="12" key={book.id} className="mb-4">
      <a
        href={book.amazonLink}
        target="_blank"
        rel="noopener noreferrer"
        className="book-card-link"
      >
        <Card className="book-card">
          <CardBody>
            <img src={book.image} alt={book.name} className="book-image" />
            <CardTitle tag="h5">{book.name}</CardTitle>
            <CardText>{book.description}</CardText>
          </CardBody>
        </Card>
      </a>
    </Col>
  );

  return (
    <>
      <CanonicalTag url="/books" />
      <div className="books-page">
        <Container className="py-4">
          <Row className="hero-section text-center">
            <Col>
              <h1 className="title">Explore Our Book Collection</h1>
              <p className="hero-description">
                Explore our curated book collection designed to enhance your
                programming skills. From beginner guides to advanced topics,
                find the perfect resource to suit your learning needs. Use the
                search function to easily locate and delve into detailed book
                insights and recommendations.
              </p>
              <div className="search-container">
                <InputGroup style={{ maxWidth: "650px", margin: "0 auto" }}>
                  <InputGroupText>
                    <FaSearch />
                  </InputGroupText>
                  <Input
                    type="text"
                    placeholder="Search for books..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    aria-label="Search for books"
                  />
                  {searchTerm && (
                    <Button
                      color="light"
                      className="clear-button"
                      onClick={handleClearSearch}
                      style={{
                        border: "1px solid #ced4da", // Border to match the search icon
                        borderRadius: "0 4px 4px 0", // Adjust border radius
                        boxShadow: "none", // Remove default button shadow
                      }}
                    >
                      <FaTimes />
                    </Button>
                  )}
                </InputGroup>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            {displayedBooks.length > 0 ? (
              displayedBooks.map(renderBookCard)
            ) : (
              <Col className="text-center no-results">
                <h5>No books found.</h5>
                <p>Try adjusting your search or checking back later.</p>
                <img
                  src={`${process.env.PUBLIC_URL}/images/404-error.png`}
                  alt="No results"
                  className="img-fluid"
                />
              </Col>
            )}
          </Row>
          {pageCount > 1 && (
            <Row>
              <Col className="d-flex justify-content-center mt-4">
                <ReactPaginate
                  previousLabel={isMobile ? <FaChevronLeft /> : "Previous"}
                  nextLabel={isMobile ? <FaChevronRight /> : "Next"}
                  breakLabel={"..."}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageChange}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                  forcePage={currentPage}
                />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </>
  );
};

export default BooksPage;
